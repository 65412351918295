<template lang="pug">
.lcap-chart
	LcapModal.line-chart-modal(
		v-model="modalOpen",
		close-on-backdrop-click,
		hide-footer
	)
		h1.chart-title {{ metric && `${metric.name} - ${activeCategory}` }}
		.line-chart-wrapper
			#line-chart(ref="lineChart")
	.desc-and-filters(v-if="metric")
		ChartDescription.chart-desc(
			:metric="metric",
			:is-editing="isEditing",
			@updateMetric="$emit('updateMetric', $event)"
		)
		.filters
			SelectInput.input(
				v-model="school",
				label="School",
				:options="schoolOptions"
			)
			SelectInput.input(
				v-model="filterKey",
				label="Filter Demographics",
				:options="filterOptions || []"
			)
			SelectInput.input(
				v-model="timeframe",
				label="Time Frame",
				:options="timeframeOptions || []"
			)
			button.clear-btn.btn(v-if="showClearBtn", @click="resetFilters") Clear Filters
	.bar-chart-wrapper
		h1.chart-title {{ metric && metric.name }}
		.bar-chart-container(ref="barChartContainer", @scroll="showScrollBtnCheck")
			#bar-chart(ref="barChart", :style="{ 'min-width': barChartMinWidth }")
		.right-gradient.gradient
		.left-gradient.gradient
		transition(name="scroll-btn-fade")
			button.scroll-btn.btn.btn-primary(
				v-if="!stopShowScrollBtn",
				@click="onScrollBtnClick"
			)
				ChevronLeftIcon.icon-left(v-if="!showScrollBtn")
				.text Scroll
				ChevronRightIcon.icon(v-if="showScrollBtn")
</template>

<script>
import { debounce } from "debounce";
import ApexCharts from "apexcharts";
import ChartDescription from "@/components/ChartDescription.vue";
import LcapModal from "@/components/LcapModal.vue";
import SelectInput from "@/components/SelectInput.vue";
import ChevronLeftIcon from "@/components/icons/ChevronLeftIcon.vue";
import ChevronRightIcon from "@/components/icons/ChevronRightIcon.vue";
import { schoolList } from "@/parse";
import { sortTimeframes, arraysEqual } from "@/util";

const formatPercentage = (num) => {
	if (typeof num !== "number") return `0%`;
	return `${+(num * 100).toFixed(2)}%`;
};

// const CHART_COLORS = [
// 	"#003E78",
// 	"#FF65B8",
// 	"#FFAF65",
// 	"#4B2DA0",
// 	"#DDDF63",
// 	"#EA7000",
// 	"#85C5F3",
// 	"#003E78",
// 	"#D44F6F",
// 	"#49575F",
// 	"#8BD8CA",
// ];

export default {
	name: "LcapChart",
	components: {
		ChartDescription,
		LcapModal,
		SelectInput,
		ChevronRightIcon,
		ChevronLeftIcon,
	},
	props: {
		/**
		 * key: string;
		 * name: string;
		 * desc: Record | string;
		 * data: string;
		 */
		metric: {
			type: Object,
			default: null,
		},
		/**
		 * data: Array;
		 * xAxis: string;
		 * yAxis: string;
		 */
		metricData: {
			type: Object,
			default: null,
		},
		filters: {
			type: Array,
			default: null,
		},
		isEditing: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			// Select v-model values for filtering.
			school: "0",
			timeframe: "",
			filterKey: "",

			barChart: null,
			barChartMinWidth: "",
			showScrollBtn: false,
			// Tracks whether or not the scroll button should show up again. E.g.,
			// we don't want the scroll button to keep showing up right after it
			// disappears!
			stopShowScrollBtn: false,
			// Debounced function to recalculate whether or not to show scroll btn.
			showScrollBtnCheck: null,

			lineChart: null,
			lineChartColor: "",
			// The category to use when displaying the line chart.
			activeCategory: "",
			debouncedRenderBarChart: null,
			// Tracks how many times the resize observer calls the debounced
			resizeObserverCallCount: 0,
			modalOpen: false, // lineChart modal
		};
	},
	computed: {
		activeFilter() {
			if (!this.filters) return;
			return this.filters.find((f) => f.key === this.filterKey);
		},
		timeframes() {
			if (!this.schoolData) return;
			const tfs = {};
			this.schoolData.d.forEach(({ d }) =>
				d.forEach(({ tf }) => {
					// Dirty data can include an empty tf.
					if (!tf) return;
					tfs[tf] = true;
				})
			);
			return sortTimeframes(Object.keys(tfs));
		},
		filterOptions() {
			if (!this.filters) return;
			return [{ label: "All", value: "" }].concat(
				this.filters.map((f) => ({
					label: f.title,
					value: f.key,
				}))
			);
		},
		schoolOptions() {
			return Object.entries(schoolList).map(([id, label]) => ({
				value: id,
				label,
			}));
		},
		timeframeOptions() {
			if (!this.timeframes) return;
			return this.timeframes.map((tf) => ({ label: tf, value: tf }));
		},
		showClearBtn() {
			if (!this.timeframes) return;
			return (
				this.school !== "0" ||
				this.timeframe !== this.timeframes[0] ||
				this.filterKey !== ""
			);
		},
		// Points to the object in data.data corresponding to the selected
		// school filter.
		schoolData() {
			if (!this.metricData) return null;
			/**
			 * Array<{
			 *   code: string; // school filter id
			 *   d: Array;
			 * }>
			 */
			return this.metricData.data.find((d) => d.code == this.school);
		},

		// Returns an object filtered.......
		timeframeData() {
			if (!this.schoolData) return null;
			return this.schoolData.d.map(({ d }) => {
				d;
			});
		},

		/**
		 * Returns Array<{
		 *   label: string; // category
		 *   d: DataPoint;  // data point object
		 * }>
		 */
		barChartDataPoints() {
			if (!this.metric || !this.schoolData) return;
			return (
				this.schoolData.d
					.map(({ label, d }) => ({
						label,
						d: d.find(({ tf }) => tf === this.timeframe),
					}))
					// Some data points are missing!
					.filter(({ d }) => d !== undefined)
					.filter(({ label }) => {
						if (!this.activeFilter) return true;
						// If a filter is chosen, then we only allow categories that exist
						// within the currently selected filter.
						return this.activeFilter.bars.find((b) => b.title === label);
					})
			);
		},

		/**
		 * Basically just takes barChartDataPoints and maps it to a format
		 * apex charts needs. ie, { x, y } objects.
		 */
		barChartData() {
			if (!this.barChartDataPoints) return;
			return this.barChartDataPoints.map(({ label, d }) => ({
				x: label,
				y: this.valFromDataPoint(d),
			}));
		},

		chartColors() {
			if (!this.barChartDataPoints) return;
			return (
				this.barChartDataPoints
					.map(({ d }) => d.color)
					// Sometimes the color isn't a valid hex value...
					// SPREADSHEETS SUCK
					.map((c) => (c.length > 7 ? c.slice(0, 7) : c))
			);
		},

		lineChartDataPoints() {
			return this.timeframes.map((timeframe) => ({
				label: timeframe,
				d: this.schoolData.d
					.find(({ label }) => label === this.activeCategory)
					.d.find(({ tf }) => tf === timeframe),
			}));
		},

		lineChartData() {
			if (!this.lineChartDataPoints) return;
			return this.lineChartDataPoints.map(({ label, d }) => ({
				x: label,
				y: this.valFromDataPoint(d),
			}));
		},

		barChartOptions() {
			if (!this.metric || !this.barChartData || !this.chartColors) return;
			return {
				chart: {
					type: "bar",
					height: "100%",
					events: {
						click: this.onBarClick,
					},
					// Redrawing on resize is manually controlled by
					// barChartContainerObserver.
					redrawOnParentResize: false,
					redrawOnWindowResize: false,
					distributed: true,
					toolbar: {
						show: false,
					},
					animations: {
						enabled: true,
						easing: "easeinout",
						speed: 500,
						animateGradually: {
							enabled: true,
							delay: 150,
						},
						dynamicAnimation: {
							enabled: false,
							speed: 350,
						},
					},
				},
				plotOptions: {
					bar: {
						distributed: true,
						dataLabels: {
							position: "top",
						},
					},
				},
				legend: {
					show: false,
				},
				colors: this.chartColors,
				series: [
					{
						name: this.metric.name,
						data: this.barChartData,
					},
				],
				dataLabels: {
					enabled: true,
					formatter: formatPercentage,
					offsetY: -20,
					style: {
						colors: ["rgb(79, 79, 79)"],
					},
				},
				xaxis: {
					labels: {
						maxHeight: 200, // Some labels are pretty dang large.
					},
				},
				yaxis: {
					max: this.getMaxYAxis(this.barChartDataPoints),
					title: {
						// text: "The Y Axis",
					},
					labels: {
						formatter: formatPercentage,
					},
				},
				tooltip: {
					enabled: true,
					x: {
						show: true,
					},
					y: {
						title: {
							formatter: (seriesName) => seriesName,
						},
						formatter: (val, { dataPointIndex }) => {
							// Here we can display the num/den value.
							const d = this.barChartDataPoints[dataPointIndex].d;
							if (!d) return;
							return !d.den ? `${d.rate}%` : `${d.num}/${d.den}`;
						},
					},
				},
			};
		},

		lineChartOptions() {
			return {
				chart: {
					type: "line",
					curve: "smooth",
					height: "100%",
					toolbar: {
						show: false,
					},
					selection: {
						enabled: false,
					},
					zoom: {
						enabled: false,
					},
					animations: {
						enabled: false,
					},
				},
				colors: [this.lineChartColor],
				series: [
					{
						name: this.metric.name,
						data: this.lineChartData,
					},
				],
				markers: {
					size: 10,
					strokeWidth: 5,
					strokeOpacity: 1,
					hover: {
						sizeOffset: 2,
					},
				},
				dataLabels: {
					enabled: true,
					formatter: formatPercentage,
					offsetY: -10,
				},
				xaxis: {
					labels: {
						// 0% values can block the label.
						offsetY: 5,
					},
				},
				yaxis: {
					min: 0,
					max: this.getMaxYAxis(this.lineChartDataPoints),
					labels: {
						formatter: formatPercentage,
					},
				},
				tooltip: {
					enabled: true,
					x: {
						show: true,
					},
					y: {
						title: {
							formatter: (seriesName) => seriesName,
						},
						formatter: (val, { dataPointIndex }) => {
							// Here we can display the num/den value.
							const d = this.lineChartDataPoints[dataPointIndex].d;
							if (!d) return;
							return !d.den ? `${d.rate}%` : `${d.num}/${d.den}`;
						},
					},
				},
			};
		},
	},

	watch: {
		barChartData: {
			immediate: true,
			handler(d) {
				if (d) {
					if (!this.debouncedRenderBarChart) return;
					// console.log("watcher");
					this.debouncedRenderBarChart();
				}
			},
		},
		timeframes: {
			immediate: true,
			handler(curr, prev) {
				if (curr) {
					if (arraysEqual(curr, prev)) return;
					this.timeframe = curr[0];
				}
			},
		},
		metricData: {
			immediate: true,
			handler() {
				this.stopShowScrollBtn = false;
			},
		},
		metric: {
			immediate: true,
			handler() {
				this.resetFilters();
			},
		},
	},

	created() {
		this.showScrollBtnCheck = debounce(this.checkBounds.bind(this), 500);
		this.debouncedRenderBarChart = debounce((fromResizeObserver) => {
			if (fromResizeObserver) {
				const callCnt = this.resizeObserverCallCount;
				this.resizeObserverCallCount = 0;
				if (callCnt <= 1) return;
			}
			this.renderBarChart(true);
		}, 500);
	},

	mounted() {
		this.barChartContainerObserver = new ResizeObserver(() => {
			// console.log("resize observer");
			this.resizeObserverCallCount += 1;
			this.showScrollBtnCheck();
			this.debouncedRenderBarChart(true);
		});
		this.barChartContainerObserver.observe(this.$refs.barChartContainer);
	},

	beforeDestroy() {
		this.barChartContainerObserver.disconnect();
		if (this.barChart) this.barChart.destroy();
		if (this.lineChart) this.lineChart.destroy();
	},

	methods: {
		valFromDataPoint(d) {
			if (!d || (!d.rate && d.den == 0)) return 0;
			// d.rate provided as a string in nondecimal percentage ("100" is 100%, or 1.0)
			// Thus, need to divide by 100.
			const rate = d.rate ? Math.min(Number(d.rate) / 100, 1) : d.num / d.den;
			return rate;
		},

		getMaxYAxis(datapoints) {
			let max = 0;
			for (const { d } of datapoints) {
				const v = this.valFromDataPoint(d);
				if (v > max) {
					max = v;
				}
			}
			return [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1].find(
				(v) => v > max
			);
		},

		onBarClick(ev, chartContext, { dataPointIndex, config }) {
			// console.log("Clicked bar", ev, chartContext, dataPointIndex, config);
			// -1 when something other than a bar is clicked.
			if (dataPointIndex === -1) return;
			const color = config.colors[dataPointIndex % config.colors.length];
			this.lineChartColor = color;
			this.activeCategory = this.barChartData[dataPointIndex].x;
			this.renderLineChart();
		},

		renderBarChart(animate = false) {
			if (!this.barChartOptions) return null;

			// The minimum width for the bar chart will depend on however
			// many columns are being displayed.
			const numOfCols = this.barChartData.length;
			this.barChartMinWidth = numOfCols * 60 + "px"; // 60 seems to be a good number.

			this.$nextTick(() => {
				this.showScrollBtnCheck();
				if (this.barChart) {
					// booleans: redrawPaths, animate. Just controls animation stuff.
					this.barChart.updateOptions(this.barChartOptions, animate, animate);
				} else {
					this.barChart = new ApexCharts(
						document.querySelector("#bar-chart"),
						this.barChartOptions
					);
					this.barChart.render();
				}
			});
		},

		renderLineChart() {
			if (!this.lineChartOptions) return null;
			if (this.lineChart) this.lineChart.destroy();
			this.modalOpen = true;
			this.$nextTick(() => {
				this.lineChart = new ApexCharts(
					document.querySelector("#line-chart"),
					this.lineChartOptions
				);
				this.lineChart.render();
			});
		},

		checkBounds() {
			const div = this.$refs.barChartContainer;
			if (!div) return;

			const noScroll = div.scrollWidth <= div.offsetWidth;
			const atEnd = div.scrollLeft === div.scrollWidth - div.offsetWidth;
			if (atEnd) {
				this.showScrollBtn = false;
				// this.stopShowScrollBtn = true;
			// } else if (!this.stopShowScrollBtn) {
			// 	this.showScrollBtn = true;
			} else this.showScrollBtn = true;
			if (noScroll) this.stopShowScrollBtn = true;
			else this.stopShowScrollBtn = false;
		},

		onScrollBtnClick() {
			// this.stopShowScrollBtn = true;
			// if (this.showScrollBtn)this.showScrollBtn = !this.showScrollBtn;
			if (this.showScrollBtn) this.$refs.barChartContainer.scrollTo({ left: 1 << 30, behavior: "smooth" });
			else this.$refs.barChartContainer.scrollTo({ left: 0, behavior: "smooth" });
		},

		resetFilters() {
			this.school = "0";
			this.filterKey = "";
			if (!this.timeframes) return;
			this.timeframe = this.timeframes[0];
		},
	},
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";

.scroll-btn-fade-enter,
.scroll-btn-fade-leave-to {
	opacity: 0;
}

.scroll-btn-fade-enter-active,
.scroll-btn-fade-leave-active {
	transition: opacity 0.3s ease;
}

.line-chart-modal {
	z-index: 11;
	::v-deep .lcap-modal-content {
		width: 100vw;
		max-width: 100vw;
		@include md {
		}
	}
	.line-chart-wrapper {
		flex: 1;
		padding: 40px;
		overflow-x: auto;
		overflow-y: hidden;
		#line-chart {
			overflow: visible;
			min-height: 500px;
			min-width: 800px;
			max-height: calc(100vh - 200px);
		}
	}
}

.chart-title {
	text-align: center;
	margin: 20px 40px;
	margin-bottom: 0;
	font-size: 20px;
}

.lcap-chart {
	overflow: visible;
	display: flex;
	flex-direction: column;

	.desc-and-filters {
		display: flex;
		flex-direction: column;
		gap: 16px;
		.chart-desc {
			flex: 1;
			max-width: 500px;
		}
		.filters {
			flex: 1;
			display: flex;
			flex-direction: column;
			gap: 8px;
			.input {
				flex: 1;
			}
			.clear-btn {
				flex: 0 0 auto;
				// align-self: center;
			}
		}
		@include sm {
			.filters {
				flex-direction: row;
				align-items: center;
			}
		}
		@include lg {
			flex-direction: row;
			align-items: center;
			* {
				flex: 1;
				min-width: 0;
			}
		}
		@include xl {
			.filters {
				flex-direction: row;
				gap: 16px;
				margin-left: auto;
				max-width: 1200px;
			}
		}
	}

	.bar-chart-wrapper {
		flex: 1;
		display: flex;
		flex-direction: column;
		position: relative;
		z-index: 0;
		width: 100%;
		border: $border-lightgray;
		background: white;
		margin-top: 20px;
		.bar-chart-container {
			position: relative;
			flex: 1;
			padding: 20px;
			overflow-x: auto;
			#bar-chart {
				min-height: 400px;
				max-height: 800px;
			}
		}
		.gradient {
			position: absolute;
			right: 0;
			height: calc(
				100% - 20px
			); // Prevents from showing over the bottom scrollbar.
			width: 40px;
		}
		.left-gradient {
			background: linear-gradient(
				90deg,
				#ffffff 0%,
				rgba(255, 255, 255, 0) 100%
			);
			left: 0;
		}
		.right-gradient {
			background: linear-gradient(
				270deg,
				#ffffff 0%,
				rgba(255, 255, 255, 0) 100%
			);
			// Kind of clips at 0px on mobile? for some reason?
			right: -1px;
		}
		.scroll-btn {
			position: absolute;
			bottom: 30px;
			left: 50%;
			transform: translateX(-50%);
			font-weight: 400;
			.icon {
				margin-left: 16px;
			}
			.icon-left {
				margin-right: 16px;
			}
		}
	}
}
</style>
